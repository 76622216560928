<template>
  <div class="page-body-box">
    <page-main back :title="`${primaryText} ${descText}`">
      <div class="col272e40" style="margin: 20px;">
        <div class="pdg-lr60 pdg-tb30">
          <div class="flex-start">
            <div class="grow-shrink0">课程标题：</div>
            <div class="w100v pdg-lr10">
              <el-input
                type="text"
                placeholder="请输入课程标题"
                maxlength="30"
                show-word-limit
                v-model="saveData.title"
              ></el-input>
            </div>
          </div>
          <div class="flex-start vertical-start mgn-t30">
            <div class="grow-shrink0">课程简介：</div>
            <div class="w100v pdg-lr10">
              <el-input
                type="textarea"
                placeholder="请输入课程简介"
                maxlength="400"
                show-word-limit
                rows="8"
                v-model="saveData.introduction"
              ></el-input>
            </div>
          </div>
          <div class="flex-start vertical-start mgn-t30">
            <div class="grow-shrink0">课程封面：</div>
            <div class="w100v pdg-lr10 flex-start vertical-end course-fen-cover">
              <el-upload
                :action='uploadApi'
                :headers='headers'
                name='image'
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                list-type='picture-card'
                accept='image/gif,image/jpeg,image/jpg,image/png,image/svg'
              >
                <img v-if="courseCover" :src="courseCover" style="width:100%;height:100%;">
                <div v-else slot="trigger" class="wrapper">
                  <div>上传封面</div>
                </div>
              </el-upload>
              <div>
                <div class="mgn-l10 colc8c9ce">你可以<span @click="onDownFace" class="col198cff pointer">点击这里下载</span>课程封面模版
                </div>
                <div class="mgn-l10 colc8c9ce">备注：封面可添加课程主题/主讲人等信息，建议尺寸750*420</div>
              </div>
            </div>
          </div>
          <div class="flex-start vertical-start mgn-t30">
            <div class="grow-shrink0">课程详情图：</div>
            <div class="w100v pdg-lr10 flex-start vertical-end detail-img">
              <el-upload
                :action='uploadApi'
                :headers='headers'
                :limit="5"
                name='image'
                :before-upload="beforeAvatarDetailUpload"
                :on-success="handleAvatarDetailSuccess"
                :on-exceed="handleExceed"
                :on-remove="handleRemove"
                :file-list="speakerCover"
                list-type='picture-card'
                multiple
                accept='image/gif,image/jpeg,image/jpg,image/png,image/svg'
              >
<!--                <img v-if="speakerCover" :src="speakerCover" style="width:100%;height:100%;">-->
                <div slot="trigger" class="wrapper">
                  <div>上传详情图</div>
                </div>
              </el-upload>
              <div>
                <div class="mgn-l10 colc8c9ce">你可以<span @click="onDownExp" class="col198cff pointer">点击这里下载</span>课程详情图模版
                </div>
                <div class="mgn-l10 colc8c9ce">备注：建议宽度750，高度无要求，最多支持上传5张</div>
              </div>
            </div>
          </div>
          <div class="flex-start vertical-start mgn-t30">
            <div class="grow-shrink0">内容分类：</div>
            <div class="w100v pdg-lr10">
              <el-select v-model="saveData.classifyId" placeholder="请选择">
                <el-option
                  v-for="item in classifyList"
                  :key="item.id"
                  :label="item.classifyName"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-button
                plain
                type="info"
                class="classify"
                @click="showSceneTagModal = true"
              >
                分类管理
              </el-button>
            </div>
          </div>
        </div>
        <div class="pdg-lr60 pdg-tb30" style="border-bottom:1px solid #E9EAEB;">
          <div class="flex-start">
            <div class="grow-shrink0">上传视频：</div>
            <div class="w100v pdg-lr10 flex-start">
              <el-button
                plain
                type="info"
                @click="onOpenVideoPop(false)"
              >
                本地上传
                <svg-icon name="el-icon-plus"/>
              </el-button>
              <el-button
                plain
                type="info"
                @click="onOpenMaterialVideoPop"
              >
                素材库上传
                <svg-icon name="el-icon-plus"/>
              </el-button>
              <span class="mgn-l10 colc8c9ce">备注：可上传多个视频，建议将较长视频分段排序上传。</span>
            </div>
          </div>
          <div class="mgn-t20" style="border-left:1px solid #ebeef5;border-right:1px solid #ebeef5;">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%">
              <el-table-column
                prop=""
                label="视频"
                width="250">
                <template slot-scope="scope">
                  <div class="video-cover"><img :src="scope.row.customCoverUrl||scope.row.coverUrl" alt=""
                    style="width:100%;height:100%;"></div>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                prop="title"
                label="视频名称">
              </el-table-column>
              <el-table-column
                prop=""
                label="分类"
                align="center"
                width="250">
                <template slot-scope="scope">
                  <div>{{ scope.row.category ? scope.row.category.title : '/' }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="排序"
                align="center"
                width="250">
                <template slot-scope="scope">
                  <div>
                    <el-button
                      v-if="scope.$index===0"
                      class="mgn-r10"
                      type="text"
                      disabled>
                      上移
                    </el-button>
                    <el-button v-else type="text" @click="onUp(scope)">
                      上移
                    </el-button>
                    <el-button v-if="scope.$index===(tableData.length-1)" type="text" disabled>
                      下移
                    </el-button>
                    <el-button v-else type="text" @click="onDown(scope)">
                      下移
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                align="center"
                label="操作"
                width="250">
                <template slot-scope="scope">
                  <div>
                    <el-button class="mgn-r10" type="text" @click="onOpenShowVideoModal(scope)">预览</el-button>
                    <el-button class="mgn-r10" type="text" @click="onOpenVideoPop(true,scope)">编辑</el-button>
                    <el-button type="text" @click="onDelVideo(scope)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="pdg-lr60 pdg-t30 pdg-b120">
          <div class="flex-start">
            <div class="grow-shrink0">题目设置：</div>
            <div class="scene-form-content">
            <el-row type="flex" align="middle">
              <el-radio-group v-model="saveData.examType" @change="onChangeExamType">
                <el-radio :label="0">分对错</el-radio>
                <el-radio :label="1">不分对错</el-radio>
              </el-radio-group>
            </el-row>
          </div>
          </div>
          <div class="flex-start">
            <div class="grow-shrink0">答题方式：</div>
            <el-radio-group v-model="saveData.answerType">
            <el-radio :label="2">顺序答题（所有题目）</el-radio>
            <el-radio :label="1">
              随机答题<span class="font-weight">（随机抽取 <el-input-number
              v-model="saveData.randomExamCount"
              :min="1"
              :max="100"
              :step="1"
              step-strictly
              :precision="0"
              class="input_number"
            /> 道题目）</span>
            </el-radio>
            </el-radio-group>

          </div>
          <div class="flex-start">
            <div class="grow-shrink0"></div>
              <el-button
                plain
                type="info"
                @click="onOpenAddQuestionPop"
              >
                添加题目
                <svg-icon name="el-icon-plus"/>
              </el-button>
          </div>
          <div class="mgn-t20" style="border-left:1px solid #ebeef5;border-right:1px solid #ebeef5;">
            <el-table
              :data="tableQuestionData"
              stripe
              style="width: 100%">
              <el-table-column
                :show-overflow-tooltip="true"
                prop="title"
                label="题目名称">
                <template slot-scope="scope">
                  {{ scope.row.title | titleFilter }}
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="题目类型"
                align="center"
                width="180">
                <template slot-scope="scope">
                  {{ scope.row.examTypeEnum | examTypeFilter }}
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="分类"
                align="center"
                width="150">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.category && scope.row.category.title || '/' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                align="center"
                label="操作">
                <template slot-scope="scope">
                  <div>
                    <el-button class="mgn-r10" type="text" @click="onOpenQuestionPreview(scope.row)">预览</el-button>
                    <el-button type="text" @click="onDelQuestion(scope)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div v-show="isShowToast" class="toast-box" :class="{'errorBg':isErrorBg}">{{ toastMessage }}</div>
    </page-main>
    <fixed-action-bar>
        <el-button @click="onCancelPop">取消</el-button>
        <el-button type="primary" @click="onSaveData">保存</el-button>
    </fixed-action-bar>

    <VideoUpload :visible="isShowVideoPop"
      :isDisableUpload="isDisableUpload"
      :initVideoObj="videoObj"
      @onUpload="onSubmitMaterialVideo"
      @onClose="onClosePop"/>

    <MaterialVideo :visible="isShowMaterialVideoPop"
      :initCheckedList="tableData"
      @onClose="onClosePop"
      @onUpload="onOpenVideoPop"
      @onSubmit="onSubmitMaterialVideo"/>

    <AddExamModal :visible="isShowAddQuestionPop"
      :examType="saveData.examType"
      @onSubmit="onSubmitExam"
      showAllExam="showAllExam"
      :selectList='tableQuestionData'
      :suitableType='suitableType'
      @onClose="onClosePop"/>

    <ExamPre
      :visible="isShowQuestionPreview"
      :isShowCorrect="!saveData.examType"
      :item-obj="questionPreviewObj"
      @onClose="onClosePop"
    />
    <OperationConfirmPop :visible="isShowOperationConfirmPop"
      :message="message"
      title="操作确认"
      cancelFont="继续编辑"
      confirmFont="确定离开"
      @onClose="onClosePop"
      @onSubmit="onSubmitPop"/>

    <VideoPlayModal title="预览视频" :isDown='false' :visible='showVideoModal' :videoUrl='videoUrl' @onClose='onClosePop'/>
    <template sl='modal'>
      <ClassificationManagement :visible='showSceneTagModal' @onClose='closeClassify'/>
    </template>
  </div>
</template>

<script>
import ImageUpload from '@/bizComponents/ImageUpload'
import VideoUpload from '@/bizComponents/VideoUpload'
import MaterialVideo from '@/bizComponents/MaterialVideo'
import AddExamModal from '@/bizComponents/AddExamModal'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import ClassificationManagement from '@/bizComponents/ClassificationManagement'
import API from '@/apis/api-types'
import {getToken} from '@/utils/auth'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CreateCourse',
  components: {
    ImageUpload,
    VideoUpload,
    MaterialVideo,
    AddExamModal,
    OperationConfirmPop,
    VideoPlayModal,
    ClassificationManagement
  },
  filters: {
    titleFilter(title) {
      const titleNew = title?.replace(/<filter><\/filter>/g, '__ ')?.replace(/,/g, '，')
      return titleNew
    },
    examTypeFilter(type) {
      const obj = {
        single: '单选',
        multiple: '多选',
        text_fill: '问答',
        blank_fill: '填空',
        sort: '排序',
        scale: '量表'
      }
      return obj[type]
    }
  },
  computed: {
    ...mapGetters(['trainClassifyList']),
    uploadApi () {
      return `${API.uploadImage}?bucketType=104`
    },
    changeSaveData () {
      return JSON.stringify(this.saveData) + JSON.stringify(this.tableData) + JSON.stringify(this.tableQuestionData)
    }
  },
  watch: {
    changeSaveData (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isSaveConfirm = false
      } else {
        this.isSaveConfirm = true
      }
    }
  },
  data () {
    return {
      headers: {
        token: getToken()
      },
      timerId: null,
      primaryText: '课程管理 - ',
      descText: '创建课程',
      isShowToast: false,
      toastMessage: '',
      isErrorBg: false,
      isSaveConfirm: false,
      // ---------------------- select -----------------
      classifyList: [],
      // ------------------------------ 上传视频弹窗 ----------------------
      isDisableUpload: false,
      videoObj: {
        title: '',
        videoImgUrl: '',
        coverUrl: '',
        categoryId: ''
      },
      // ------------------- 视频预览 ------------------
      videoUrl: '',
      // ---------------------- 弹窗 ------------------------
      isShowVideoPop: false,
      isShowMaterialVideoPop: false,
      isShowAddQuestionPop: false,
      isShowQuestionPreview: false,
      isShowOperationConfirmPop: false,
      showVideoModal: false,
      // ----------------------------- questionPreview 问题预览 ---------------------
      questionPreviewObj: {},
      // -------------------------- operationConfirmPop 离开确认--------------------
      message: '当前页面未编辑完成，您确定要离开当前编辑页面吗？',
      // ----------------------- vedio table -----------------
      courseIdArr: [],
      tableData: [],  // 课程视频列表
      // ----------------------- question table -----------------
      questionIdArr: [],
      tableQuestionData: [],  // 课程问题列表
      // --------------------------------- 取消 保存 ------------------------
      courseCover: '',     // 课程封面
      speakerCover: [],     // 详情图封面
      saveData: {
        id: '',
        title: '',    // 标题
        introduction: '',   //简介
        coverPath: '',    // 封面路径
        speakerPath: [],    // 详情图路径
        classifyId: '',   // 内容分类
        source: 0,    // 来源（0：厂家；1：平台）
        examType: 0,
        answerType: null,
        randomExamCount: null
      },
      showSceneTagModal: false,
      suitableType: '2',
      isAttribute: false,
    }
  },
  mounted () {
    this.onGetClassifyList()
    if (this.$route.params.courseId) {
      this.descText = '编辑课程'
      this.onInitTrainData()
    }
  },
  methods: {
    ...mapActions([
      'onTrainClassifyList',
      'onTrainModifyCourse',
      'getExamDetail',
      'getCourseDetail',
      'onTrainVideoList',
      'onTrainExamList',
      'getVideoUrl'
    ]),
    initPage () {
      this.saveData = {
        id: '',
        title: '',    // 标题
        introduction: '',   //简介
        coverPath: '',    // 封面路径
        speakerPath: [],    // 详情图路径
        classifyId: '',   // 内容分类
        source: 0,    // 来源（0：厂家；1：平台）
      }
      this.courseCover = ''
      this.speakerCover = []
      this.tableData = []
      this.tableQuestionData = []
    },
    onDownFace () {
      saveAs('https://yao-file.oss-cn-hangzhou.aliyuncs.com/ksh/ppt/face.pptx', '课程封面图模版')
    },
    onDownExp () {
      saveAs('https://yao-file.oss-cn-hangzhou.aliyuncs.com/ksh/ppt/detail.pptx', '课程详情图模版')
    },
    onCancelPop () {
      this.onOpenOperationConfirmPop()
    },
    onGetClassifyList () {
      this.onTrainClassifyList().then(res => {
        this.classifyList = res.data || []
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    goBack () {
      this.$router.replace('/course/management')
    },
    onInitTrainData () {
      this.getCourseDetail({courseId: this.$route.params.courseId}).then(res => {
        const resData = res.data || {}
        this.courseCover = resData.coverUrl.middle || ''
        const imgList = []
        if (resData.speakerUrl) {
          resData.speakerUrl.map(v => {
            if (v) {
              this.speakerCover.push({name: v.imageName, url: v.middle})
              imgList.push(v.imageName)
            }
          })
        }
        this.saveData = {
          id: resData.id,
          title: resData.title,
          introduction: resData.introduction,
          coverPath: resData.coverUrl.imageName,
          speakerPath: imgList,
          classifyId: resData.classifyId,
          answerType: resData.answerType,
          randomExamCount: resData.randomExamCount,
          examType: resData.examType
        }
        this.tableData = resData.videoVos
        this.tableQuestionData = resData.examVos
        this.onCourseIdArr()
        this.onQuestionIdArr()
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onInitTrainVideoList () {
      this.onTrainVideoList({courseId: this.$route.params.courseId}).then(res => {
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onInitTrainExamList () {
      this.onTrainExamList({courseId: this.$route.params.courseId}).then(res => {
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onCourseIdArr () {
      let arr = []
      for (let item of this.tableData) {
        arr.push(item.videoId)
      }
      this.courseIdArr = arr
    },
    onQuestionIdArr () {
      let arr = []
      for (let item of this.tableQuestionData) {
        arr.push(item.examId)
      }
      this.questionIdArr = arr
    },
    beforeAvatarUpload (file) {
      let _this = this
      const isSize = new Promise(function (resolve, reject) {
        let width = 750 // 限制图片尺寸为750*420
        let height = 420
        let _URL = window.URL || window.webkitURL
        let img = new Image()
        img.onload = function () {
          let valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议封面图片尺寸750*420')
        return Promise.resolve()
      })
      return isSize
    },
    handleAvatarSuccess (res, file) {
      this.courseCover = URL.createObjectURL(file.raw)
      this.$set(this.saveData, 'coverPath', res.data.imageName)
    },
    beforeAvatarDetailUpload (file) {
      let _this = this
      const isSize = new Promise(function (resolve, reject) {
        let width = 750
        let _URL = window.URL || window.webkitURL
        let img = new Image()
        img.onload = function () {
          let valid = img.width === width
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议详情图宽度为尺寸750')
        return Promise.resolve()
      })
      return isSize
    },
    handleAvatarDetailSuccess (res) {
      this.saveData.speakerPath.push(res.data.imageName)
    },
    handleExceed() {
      this.$message.warning('最多支持上传5张详情图')
    },
    handleRemove(file) {
      this.saveData.speakerPath = this.saveData.speakerPath.filter(v => v !== file.response.data.imageName)
    },
    onClosePop (options) {
      this.isShowVideoPop = false
      this.isShowMaterialVideoPop = false
      this.isShowAddQuestionPop = false
      this.isShowQuestionPreview = false
      this.isShowOperationConfirmPop = false
      this.showVideoModal = false
    },
    onSubmitMaterialVideo (options) {
      options.map((it, ind) => {
        if (!this.courseIdArr.includes(it.videoId)) {
          this.tableData.push(it)
        } else {
          this.$set(this.tableData, this.courseIdArr.indexOf(it.videoId), it)
        }
      })
      this.onCourseIdArr()
    },
    onSubmitExam (arr) {
      this.tableQuestionData = arr
    },
    onSubmitAddQuestion (options) {
      console.log(options, '------------------- onSubmitAddQuestion ----------')
      options.map((it, ind) => {
        if (!this.questionIdArr.includes(it.examId)) {
          this.tableQuestionData.push(it)
        } else {
          this.$set(this.tableQuestionData, this.questionIdArr.indexOf(it.examId), it)
        }
      })
      this.onQuestionIdArr()
    },
    onSubmitPop () {
      this.$router.replace({name: 'CourseManagement'})
    },
    onOpenVideoPop (isDisable = false, scope) {
      if (isDisable) {
        this.videoObj = Object.assign({}, scope.row, {categoryId: scope.row.category && scope.row.category.categoryId})
        this.isDisableUpload = true
      } else {
        this.videoObj = {}
        this.isDisableUpload = false
      }
      setTimeout(() => {
        this.isShowVideoPop = true
      }, 10)
    },
    onOpenMaterialVideoPop () {
      this.isShowMaterialVideoPop = true
    },
    onOpenAddQuestionPop () {
      this.isShowAddQuestionPop = true
    },
    onOpenQuestionPreview (item) {
      this.getExamDetail(item.examId).then(res => {
        this.isShowQuestionPreview = true
        this.questionPreviewObj = res.data || {}
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onOpenOperationConfirmPop () {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.goBack()
      }
    },
    onOpenShowVideoModal (scope) {
      this.getVideoUrl(scope.row.videoId).then(res => {
        let resData = res.playUrl || []
        if (!resData.length > 0) {
          this.$message.error({message: '暂无视频资源'})
        }
        for (let item of resData) {
          if (item.indexOf('.mp4') > 0) {
            this.videoUrl = item
            this.showVideoModal = true
            return false
          }
          this.videoUrl = ''
        }
        this.$message.error({message: '暂无视频资源'})
      }, rea => {
        this.$message.error({message: rea})
      })
    },
    onUp (options) {
      console.log(options)
      this.tableData.splice(options.$index, 1)
      this.tableData.splice(options.$index - 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDown (options) {
      console.log(options)
      this.tableData.splice(options.$index, 1)
      this.tableData.splice(options.$index + 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDelVideo (scope) {
      this.tableData.splice(scope.$index, 1)
      this.onCourseIdArr()
    },
    onDelQuestion (scope) {
      this.tableQuestionData.splice(scope.$index, 1)
      this.onQuestionIdArr()
    },
    onClearTimeout () {
      this.timerId && clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        this.isShowToast = false
      }, 3000)
    },
    onCheckData () {
      this.onClearTimeout()
      if (!this.saveData.title) {
        this.isShowToast = true
        this.toastMessage = '请填写课程标题'
        this.isErrorBg = true
        return false
      } else if (!this.saveData.introduction) {
        this.isShowToast = true
        this.toastMessage = '请填写课程简介'
        this.isErrorBg = true
        return false
      } else if (!this.saveData.coverPath) {
        this.isShowToast = true
        this.toastMessage = '请上传课程封面'
        this.isErrorBg = true
        return false
      } else if (!this.saveData.classifyId) {
        this.isShowToast = true
        this.toastMessage = '请选择课程分类'
        this.isErrorBg = true
        return false
      }
      if (!(this.tableData.length > 0)) {
        this.isShowToast = true
        this.toastMessage = '请上传视频'
        this.isErrorBg = true
        return false
      }
      if (!(this.tableQuestionData.length >= 1)) {
        this.isShowToast = true
        this.toastMessage = '请至少添加1道问题'
        this.isErrorBg = true
        return false
      }
      if (this.tableQuestionData.length >= 100) {
        this.isShowToast = true
        this.toastMessage = '最多只能添加100道问题'
        this.isErrorBg = true
        return false
      }
      return true
    },
    onSaveData () {
      console.log(this.saveData, '------------------- saveData --------------')
      let isSubmit = this.onCheckData()
      if (!isSubmit) return false
      let videoList = []
      this.tableData.map((item, index) => {
        videoList.push({
          videoId: item.videoId,
          videoIndex: index
        })
      })
      let questionList = []
      this.tableQuestionData.map((item, index) => {
        questionList.push({
          examId: item.examId,
          examIndex: index,
        })
      })
      let course = {...this.saveData, speakerPath: this.saveData.speakerPath.toString()}
      let Data = Object.assign({}, {
        course: course,
        courseVideos: videoList,
        courseExams: questionList,
      })
      console.log(Data, '------------------- 创建课程 ------------------------------')
      this.onTrainModifyCourse(Data).then(res => {
        this.isShowToast = true
        this.toastMessage = '保存成功'
        this.isErrorBg = false
        this.isSaveConfirm = false
        setTimeout(() => {
          this.$router.push({name: 'CourseManagement'})
        }, 1000)
      }, rea => {
        this.onClearTimeout()
        this.isShowToast = true
        this.toastMessage = rea.message
        this.isErrorBg = true
        this.isSaveConfirm = false
      })
    },
    closeClassify () {
      this.showSceneTagModal = false
      this.onGetClassifyList()
    },
    onChangeExamType () {
      this.tableQuestionData = []
    },
    onOpenAddExamPop () {
      if (this.isAttribute) return this.$message.error('目前该活动已分配给医生，题目不支持修改')
      this.isShowAddExamModal = true
    },
  }
}
</script>

<style>
.course-fen-cover .el-upload--picture-card {
  width: 160px;
  height: 90px;
  background-color: #e2e3e7;
  border: 1px dashed #3D61E3;
  border-radius: 0;
}

.detail-img .el-upload-list__item {
  width: 100px;
  height: 100px;
  background-color: #e2e3e7;
  border: 1px dashed #3D61E3;
  border-radius: 0;
}
.el-upload--picture-card {
  width: 100px;
  height: 100px;
  background-color: #e2e3e7;
  border: 1px dashed #3D61E3;
  border-radius: 0;
}
</style>
<style scoped>
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #3D61E3;
  font-size: 14px;
  font-weight: bold;
}

.btn-item {
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #3D61E3;
  border-radius: 4px;
  border: 1px solid #3D61E3;
  margin-right: 10px;
}

.content-box::-webkit-scrollbar {
  width: 0 !important;
}

.video-cover {
  width: 107px;
  height: 60px;
  background: #D8D8D8;
  margin-right: 20px;
  text-align: center;
}

.toast-box {
  color: #ffffff;
  background: green;
}

.errorBg {
  background: #d8384a;
}

.classify {
  color: #3D61E3;
  border-color: #3D61E3;
  width: 140px;
  margin-left: 8px;
}

.grow-shrink0 {
  min-width: 80px;
  text-align: right;
}
.speaker-cover {
  display: inline-block;
  height: 100px;
}
</style>

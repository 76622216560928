<template>
  <div class="material-video-box">
    <el-dialog
      class="customize-el-dialog"
      title="上传视频"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="false"
      width="850"
    >
      <div style="border-bottom: 1px solid #e9eaeb">
        <div class="flex-start mgn-b20">
          <div class="material-video-search-box">
            <el-input
              placeholder="搜索内容"
              suffix-icon="el-icon-search"
              @change="onSearchChange"
              v-model="searchData.searchKey"
            >
            </el-input>
          </div>
          <div class="w100v pdg-lr10">
            <el-select
              v-model="searchData.categoryId"
              @change="onSelectChange"
              placeholder="选择分类"
            >
              <el-option
                v-for="item in videoClassifyList"
                :key="item.categoryId"
                :label="item.title"
                :value="item.categoryId"
              >
              </el-option>
            </el-select>
          </div>
          <span class="text-primary-link" @click="onCreate">本地上传</span>
        </div>
        <div v-if="tableDataList.length !== 0" style="height: 300px;overflow-y: auto;">
          <template v-for="(item, index) of tableDataList">
            <div
              :key="index"
              class="flex-start vertical-center pdg-tb10 pdg-lr20"
              :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
            >
              <el-checkbox
                :value="isSelect(item.videoId)"
                @change="onCheckedChange(item)"
              ></el-checkbox>
              <div class="video-info-box flex-start mgn-l20">
                <div class="video-cover">
                  <img
                    :src="item.customCoverUrl"
                    alt=""
                    style="width: 100%; height: 100%"
                  />
                </div>
                <div class="video-describe-box">
                  <div class="pdg-b12 col272e40">{{ item.title }}</div>
                  <div class="col7784a1 font-s14">
                    <span
                      >视频时长(分钟)：{{
                        parseFloat(((item.duration || 0) / 60).toFixed(1))
                      }}</span
                    >
                    <span v-if="showVideoDuration" style="margin-left: 10px">
                      <!-- （提示：视频时长{{ item.duration >= 60 ? parseInt(item.duration / 60) + '分' + (item.duration % (parseInt(item.duration / 60) * 60)) + '秒' : item.duration + '秒' }}，
              科会观看时长要求{{ item.duration * 0.95 >= 60 ? parseInt(item.duration * 0.95 / 60) + '分' + parseInt((item.duration * 0.95 % (parseInt(item.duration * 0.95 / 60) * 60))) + '秒' : parseInt(item.duration * 0.95) + '秒' }}） -->
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="col909399 text-ct pdg30">
          暂无数据
        </div>
        <el-pagination
          class="sorter-box"
          @current-change="onCurrentChange"
          :current-page="searchData.pageNo"
          :page-size="searchData.pageSize"
          :total="total"
          background
          hide-on-single-page
          layout="total, prev, pager, next"
          small
        ></el-pagination>
      </div>
      <div slot="footer" class="flex-center">
        <el-button @click="onClose">取消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MaterialVideo",
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: false,
    },
    showVideoDuration: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 100,
    },
    initCheckedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {},
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.initPage();
      }
    },
  },
  data() {
    return {
      // -------------------- 搜索、页数 -----------------
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 5,
        categoryId: "",
        searchKey: "",
      },
      // ---------------------- select -----------------
      videoClassifyList: [],
      // -------------------- submit ----------------
      btnLoading: false,
      // ----------------------- vedio table -----------------
      tableDataList: [],
      selectedList: [],
      //
    };
  },

  methods: {
    ...mapActions(["onGetTrainVideoList", "onGetCategoryList"]),
    onClose() {
      this.$emit("onClose", false);
    },
    onCreate() {
      this.$emit("onUpload", false);
      this.onClose();
    },
    onSubmit() {
      this.$emit("onSubmit", this.selectedList);
      this.onClose();
    },
    onSearchChange() {
      this.$set(this.searchData, "pageNo", 1);
      this.onVideoList();
    },
    onSelectChange() {
      this.$set(this.searchData, "pageNo", 1);
      this.onVideoList();
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 5,
        categoryId: "",
        searchKey: "",
      };
      this.selectedList = this.initCheckedList;
      this.tableDataList = [];
      this.onVideoList();
      this.onGetVideoCategoryList();
    },
    onGetVideoCategoryList() {
      this.onGetCategoryList({ categoryType: "video" }).then((res) => {
        let list = [
          {
            categoryId: "",
            title: "全部",
          },
        ];
        this.videoClassifyList = list.concat(res.data || []);
      });
    },
    onVideoList() {
      this.onGetTrainVideoList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0;
          this.tableDataList = data || [];
        }
      );
    },
    isSelect(currentId) {
      return (
        [...this.selectedList].map((v) => v.videoId).indexOf(currentId) > -1
      );
    },
    onCheckedChange(item) {
      if (this.isSelect(item.videoId)) {
        this.selectedList = [...this.selectedList].filter(
          (v) => v.videoId !== item.videoId
        );
      } else {
        if (this.limit === 1) {
          this.selectedList = [];
        }
        this.selectedList = [...this.selectedList, item];
      }
    },
    onChange(options) {},
    onCurrentChange(options) {
      this.$set(this.searchData, "pageNo", options);
      this.onVideoList();
    },
  },
};
</script>

<style lang="scss" scoped>
.material-video-search-box .el-input input {
  width: 250px;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .material-video-box {
    ::v-deep .el-dialog__wrapper .el-dialog {
      height: 80% !important;

      .el-dialog__body {
        padding-top: 20px !important;
        overflow: auto;
        height: 80% !important;
      }
    }
  }
}

.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin-right: 20px;
}

.bgf6f7fb {
  background: #f6f7fb;
}

.sorter-box {
  padding: 10px;
  text-align: right;
  background: #ffffff;
}
</style>

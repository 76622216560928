<template>
  <!--  目前培训在用-->
  <el-dialog id="edit-tag-dialog" :visible="visible">
    <div slot="title" class="dialog-title">分类管理</div>
    <el-row type="flex" justify="space-between" align="middle">
      <el-input
        v-model="createTagNameText"
        maxlength="8"
        class="none-input"
        size="large"
        show-word-limit
        placeholder="请输入分类名称"
      />
      <el-button type="primary" class="flex-shrink mgn-lr30" @click="onCreate">新建分类 </el-button>
    </el-row>
    <el-table :data="classifyList" size="small" height="300">
      <el-table-column label="所有分类">
        <template slot-scope="scope">
          <span style="line-height: 24px;">{{ scope.row.classifyName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span class="text-info-link" @click="onDel(scope.row.id)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="center" class="footer-warpper">
      <el-button plain @click="onClose">完成</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'ClassificationManagement',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      createTagNameText: '',
      classifyList: []
    }
  },
  mounted() {
    this.onTrainClassifyList().then(
      res => {
        const list = []
        this.classifyList = list.concat(JSON.parse(JSON.stringify(res.data || [])))
      },
      rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      }
    )
  },
  methods: {
    ...mapActions(['removeTrainClassify', 'creatTrainClassify', 'onTrainClassifyList']),
    onCreate() {
      const classifyName = this.createTagNameText.replace(/^\s*|\s*$/g, '')
      if (!classifyName) return this.$message.error('请输入分类名称')
      this.creatTrainClassify({ classifyName }).then(
        res => {
          this.classifyList = [{ classifyName: classifyName, id: res.data.id }, ...this.classifyList]
          this.createTagNameText = ''
          this.$message.success('分类创建成功')
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    onDel(id) {
      this.removeTrainClassify({ classifyId: id }).then(
        () => {
          this.$message.success('删除成功')
          this.classifyList = [...this.classifyList].filter(v => v.id !== id)
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-warpper {
  padding-top: 20px;
  padding-bottom: 20px;
  .el-button {
    width: 120px;
  }
}
.tab-nav {
  flex-shrink: 0;
  cursor: pointer;
  padding: 13px 40px;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  color: #080922;
  border-top: 1px solid #f1f1f1;
  background-color: #fff;
  &:hover,
  &.active {
    color: #218cff;
  }
  &.active {
    background-color: #f1f1f1;
  }
}
.none-input ::v-deep {
  .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background: none;
  }
  &.el-input--mini .el-input__inner {
    height: 24px;
    line-height: 24px;
  }
  .el-input__count-inner {
    background: none;
  }
}
</style>

<template>
  <div class="course-management-page-box page-body">
    <div class="is-fixed">
      <div class="relative" v-if="banner" @click="jumpSourceType">
        <img :src=banner alt="" class="banner">
        <i class="el-icon-close close" @click="banner = false"></i>
      </div>
      <div class="title-box">
        <div class="relative">
          <el-tabs v-model="searchData.sourceType" @tab-click='onSourceChange'>
            <el-tab-pane v-for="item in sourceList" :key='item.id' :label='item.name' :name='item.id'></el-tab-pane>
          </el-tabs>
          <div class="course-management-custom-input-box user-input-box absolute flex-between">
            <el-input placeholder="搜索课程名称" v-model="searchData.courseName">
              <el-button class="search-box" slot="append" icon="el-icon-search" @click="onSearch"></el-button>
            </el-input>
            <el-button v-if="$auth('crm.courseMgmt.create')" type="primary" @click="onGoCreateCourse" class="creat-course">创建课程</el-button>
          </div>
        </div>
        <div class="search-row-box" v-if="searchData.sourceType === '0'">
          <div class="mgn-r20">
            <div class="flex-between vertical-baseline content-box">
              <div class="grow-shrink0">内容分类：</div>
              <div class="flex-wrap classify-box-hidden" :class="{'classify-box-auto':!isPackUp}">
                <template v-for="item in classifyList">
                  <div class="content-item cursor" :class="{'active':item.isChecked}" :key="item.id"
                       @click="onClassifyChange(item)">
                    {{ item.classifyName }}
                  </div>
                </template>
              </div>
            </div>
            <div class="pack-up-btn" v-if="classifyList.length > 9">
              <el-button type="text" @click="onPackUp">{{ isPackUp ? '展开更多筛选' : '收起更多筛选' }}</el-button>
              <i @click="onPackUp" :class="isPackUp?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" ref="content" v-loading="loading">
      <div
        ref='scroll-wrapper'
        v-infinite-scroll="onLoad"
        :infinite-scroll-immediate='false'
        :infinite-scroll-distance='50'
        :infinite-scroll-disabled="loading || isEnd"
      >
        <div class="course-box">
          <div class="course-list-box">
            <div v-if="!trainCourseList.length" class="col909399 text-ct pdg30" style="width:100%;">暂无数据</div>
            <el-row :gutter="10" type="flex" class="flex-wrap">
              <el-col  :xs="4" :sm="4" :md="4" :xl="3" v-for="item in trainCourseList" :key="item.id">
                <div class="course-item-box cursor" @click.stop="onLookCourse(item.id)">
                  <div style="width:100%;height:135px;background:#D8D8D8;position: relative;">
                    <img v-if="item.coverUrl" :src="item.coverUrl.middle" alt="" style="width:100%;height:135px;">
                    <div class="time">{{ item.createTime }}</div>
                    <img src="../../assets/training/icon_buy.png" alt="" class="buy"
                         v-if="searchData.sourceType === '2' && item.isBuy">
                  </div>
                  <div class="course-item-message">
                    <div class="overflow-text2 pdg-lr15 pdg-t15 course-title">{{ item.title }}</div>
                    <div class="col7784a1 pdg-lr15 pdg-tb5" v-if="searchData.sourceType == '1'">
                      剩余{{ item.residueDegree || 0 }}次
                    </div>
                    <div class="flex-between pdg-lr15 pdg-tb5">
                      <div class="flex-start cursor pdg-b10 col198cff" @click.stop="onPreview(item.id,item.title)" v-if="searchData.sourceType !== '2' && $auth('crm.courseMgmt.preview')">
                        <img src="../../assets/meeting/m_preview_active.jpg" alt="" class="mgn-r4">
                        <span>预览课程</span>
                      </div>
                      <div class="col7784a1 pdg-b10">共{{ item.videoCount || 0 }}个视频</div>
                      <div class="pdg-b10" v-if="searchData.sourceType === '2'">
                        <span class="font-s16 font-weight">¥{{ item.markingPrice}}</span>
                        <span class="original-price" v-if="item.originalPrice">¥{{ item.originalPrice}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <SceneShareDepModal :visible="isShowShareCoursePop" :sceneType='sceneType' sceneTitle='课程' :sceneId="courseId"
                        @onClose="onClosePop"/>
    <PreviewCourseModal :visible='showPreviewModal' :previewId='previewId' :type='type' :previewType='previewType'
                        :showSend="searchData.sourceType !== '2'"
                        @onPreview='onPreviewMobile'
                        @onClose='onCloseModal'/>
    <SendPreviewModal :visible='showPreviewMobileModal' :previewId='previewId' @onSubmit='onSendToMobile'
                      @onClose='onCloseModal' title='课程预览'/>
    <template sl='modal'>
      <ClassificationManagement :visible='showSceneTagModal' @onClose='closeClassify'/>
    </template>
  </div>
</template>

<script>
import SceneShareDepModal from '@/bizComponents/SceneShareDepModal'
import PreviewCourseModal from '@/bizComponents/PreviewCourseModal'
import SendPreviewModal from '@/bizComponents/SendPreviewModal'
import ClassificationManagement from '@/bizComponents/ClassificationManagement'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CourseManagement',
  components: {
    SceneShareDepModal,
    PreviewCourseModal,
    SendPreviewModal,
    ClassificationManagement
  },
  computed: {
    ...mapGetters(['trainCourseList']),
  },
  data() {
    return {
      initSourceList: [
        {
          id: '0',
          name: '我的课程',
        },
        {
          id: '1',
          name: '企业采购'
        },
        {
          id: '2',
          name: '好课推荐'
        }
      ],
      sourceList: [],
      classifyList: [],
      isPackUp: true,   //true-收起状态，false-展开状态
      courseList: [],
      // ----------------------- 弹窗 -------------------------------
      isShowOperationConfirmPop: false,
      message: '请确认是否永久删除？',
      // ---------------------------- ShareCourse -------------------
      isShowShareCoursePop: false,
      courseId: '',
      // -------------------- 搜索、页数 -----------------
      timerId: [],
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 24,
        courseName: '',
        sourceType: '0',
        classifyId: '',
      },
      showPreviewModal: false,//预览modal
      showPreviewMobileModal: false,//发送手机预览modal
      previewId: '',//预览活动的id
      showSceneTagModal: false,
      sceneType: 'training',
      banner: '',
      previewType: 'studyPre',
      type: 'studyDetail',
      loading: false,
      isEnd: false,
      isLoadPage: false,
      isSavePage: false,
      scrollTop: 0,
    }
  },
  activated() {
    document.documentElement.scrollTop = this.scrollTop
    if (!this.isSavePage) {
      this.initPage()
    }
  },
  beforeRouteLeave(to, from, next) {
    this.scrollTop = document.documentElement.scrollTop + document.body.scrollTop
    if (to.name === 'LookCourseData') {
      this.isSavePage = true
    }
    next()
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.$nextTick(() => {
  //       if (from.name === 'EditCourse'){
  //         vm.isLoadPage = true
  //       }
  //     })
  //   })
  // },
  methods: {
    ...mapActions(['onSendPreview', 'onTrainClassifyList', 'initTrainCourseList', 'getTrainCourseList', 'onTrainRemoveCourse', 'getBannerList']),
    onShow() {
      this.$emit('onEdit')
    },
    initPage() {
      this.getBannerList({ showArea: 7 }).then(res => {
        this.banner = res.data[0].showInfo.image.normal
      })
      this.sourceList = JSON.parse(JSON.stringify(this.initSourceList))
      this.searchData = Object.assign({}, { pageNo: 1, pageSize: 24, courseName: '', sourceType: '0', classifyId: '', })
      this.onGetClassifyList()
      this.onInitTrainCourseList()
    },
    onPackUp() {
      this.isPackUp = !this.isPackUp
    },
    onGetClassifyList() {
      this.onTrainClassifyList().then(res => {
        let list = [{ id: '', classifyName: '全部', isChecked: true}]
        this.classifyList = list.concat(JSON.parse(JSON.stringify(res.data || [])))
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onInitTrainCourseList() {
      this.loading = true
      this.$set(this.searchData, 'pageNo', 1)
      this.isEnd = true
      this.initTrainCourseList(this.searchData).then(res => {
        this.searchData.pageNo++
        this.isEnd = false
        this.showEmpty = !res.length
        this.loading = false
      }, rea => {
        this.loading = false
      })
    },
    onLoad() {
      if (this.isEnd || this.loading) return
      this.loading = true
      this.getTrainCourseList(this.searchData).then(res => {
        this.searchData.pageNo++
        this.loading = false
        this.isEnd = !res.length
        if (this.isEnd) {
          this.searchData.pageNo = 1
        }
      }, rea => {
        this.loading = false
      })
    },
    jumpSourceType() {
      this.$set(this.searchData, 'sourceType', '2')
      this.onInitTrainCourseList()
    },
    onSourceChange(tab, event) {
      this.$set(this.searchData, 'sourceType', tab.name)
      this.$set(this.searchData, 'pageNo', 1)
      this.onInitTrainCourseList()
    },
    onClassifyChange(item) {
      for (let it of this.classifyList) {
        if (it.id === item.id) {
          this.$set(it, 'isChecked', true)
        } else {
          this.$set(it, 'isChecked', false)
        }
      }
      this.$set(this.searchData, 'classifyId', item.id)
      this.$set(this.searchData, 'pageNo', 1)
      this.onInitTrainCourseList()
    },
    onSearch() {
      this.onInitTrainCourseList()
    },
    onGoCreateCourse() {
      this.$router.push({ name: 'CreateCourse' })
    },
    onClosePop() {
      this.isShowShareCoursePop = false
      this.isShowOperationConfirmPop = false
    },
    onOpenShareCoursePop(id) {
      this.courseId = id
      this.isShowShareCoursePop = true
    },
    onCloseModal() {
      this.showPreviewModal = false
      this.showPreviewMobileModal = false
      this.previewId = ''
      this.previewTitle = ''
    },
    onPreview(id, title) {
      this.previewId = id
      this.previewTitle = title
      this.showPreviewModal = true
    },
    onPreviewMobile() {
      if (this.searchData.sourceType === '2') {
        return false
      }
      this.showPreviewModal = false
      this.showPreviewMobileModal = true
    },
    onSendToMobile(mobile) {
      let req = {
        representMobile: [mobile],
        sceneId: this.previewId,
        sceneType: 'training',
        sceneTitle: this.previewTitle
      }
      this.onSendPreview(req).then(res => {
        this.$message.success('发送成功')
        this.onCloseModal()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onLookCourse(id) {
      this.$router.push({ name: 'LookCourseData', params: { courseId: id } })
    },
    closeClassify() {
      this.showSceneTagModal = false
      this.onGetClassifyList()
    }
  },
}
</script>

<style>
.course-management-page-box .course-management-custom-input-box i.el-icon-search {
  color: #ffffff;
}

.course-management-page-box .course-management-custom-input-box .search-box {
  background: #3D61E3 !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.course-management-page-box .course-management-custom-input-box .el-input-group__append, .el-input-group__prepend {
  border: 0px;
  background: #ffffff;
}

.course-management-page-box .course-management-custom-input-box .el-input-group--append .el-input__inner {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

</style>
<style lang="scss" scoped>
::v-deep .el-tabs .el-tabs__header {
  margin-bottom: 0;
}
.course-management-page-box {
  display: flex;
  justify-content: flex-start;
}
.page-body{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #F6F7FB;
}
.is-fixed {
  position: sticky;
  top: 0;
  z-index: 15;
  background: #ffffff;
}

.el-tabs ::v-deep {
  .el-tabs__nav-wrap {
    padding-left: 50px;
  }
}

.title-box {
  background: #ffffff;
}

.classify {
  padding: 10px 50px;
}

.user-input-box {
  flex-shrink: 0;
  top: 3px;
  right: 80px;
  width: 400px;
}

.content-box {
  text-align: center;
  color: #7784A1;
  padding-top: 10px;
  padding-left: 30px;
}

.content-item {
  min-width: 120px;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 15px;
}

.active {
  color: #ffffff;
  background: #3D61E3;
}

.classify-box-hidden {
  height: 50px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.classify-box-auto {
  height: auto !important;
  overflow: auto !important;
}

.pack-up-btn {
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #47a3ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: #F6F7FB;
  flex: 1;
  //overflow-y: auto;
  padding:20px;
}

.course-item-box {
  max-width: 240px;
  margin-bottom: 30px;
  background: #ffffff;
  display: flex;
  flex-flow: column nowrap;
}

.course-item-message {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1;
}

.course-title {
  color: #272E40;
  font-size: 14px;
  font-weight: bold;
  height: 52px;
}

.course-list-box::-webkit-scrollbar {
  width: 0 !important;
}

.sorter-box {
  padding: 30px;
  text-align: right;
  background: #ffffff;
}

.time {
  font-size: 12px;
  color: #fff;
  height: 20px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  position: absolute;
  right: 10px;
  top: 7px;
}

.course-list-box .flex-wrap {
  margin: 0;
}

.banner {
  max-height: 80px;
  width: 100%;
  cursor: pointer;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.buy {
  position: absolute;
  top: 5px;
  left: 5px;
}

.search-row-box {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px 20px;
}

.creat-course {
  margin-left: 20px;
}
.original-price {
  margin-left: 5px;
  text-decoration: line-through red
}
</style>

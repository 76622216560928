<template>
  <div>
    <div>
      <page-main back title="">
        <el-button type="text" class="contact" @click="contact" :disabled="isClick"
                   v-if="courseDetail.sourceType === 2 && !courseDetail.isBuy">对课有兴趣？联系我吧！
        </el-button>
        <div class="flex-start vertical-start">
          <div class="grow-shrink0 mgn-r30 relative course-img" style=""
               @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" v-if="courseDetail.sourceType === 2">
            <img v-if="courseDetail.coverUrl" :src="courseDetail.coverUrl.middle" alt=""
                 style="width:100%;height:100%;">
            <div class="code-mask" :class="{active:showMask}" element-loading-background="rgba(0, 0, 0, 0.8)"
                 @click="onOpenShowVideoModal(courseDetail.videoVos[0].videoId)">
              <el-row type="flex" align="middle">
                <el-row type="flex" align="middle" justify="center">
                  <i class="el-icon-video-play video-play"></i>
                </el-row>
              </el-row>
            </div>
          </div>
          <div class="grow-shrink0 mgn-r30 relative course-img" v-else>
            <img v-if="courseDetail.coverUrl" :src="courseDetail.coverUrl.middle" alt=""
                 style="width:100%;height:100%;">
          </div>
          <div>
            <div class="flex-between flex-direction-col vertical-start">
              <div class="col272e40 font-s18 font-bold">{{ courseDetail.title }}</div>
              <div class="flex-start mgn-t20" v-if="courseDetail.classifyName">
                <div class="table-box">{{ courseDetail.classifyName }}</div>
              </div>
            </div>
            <div class="mgn-t10 col7784a1 font-s14">
              <span>课程简介：</span>
              <span>{{ courseDetail.introduction }}</span>
            </div>
            <div class="mgn-t10 col7784a1 font-s14 flex"
                 v-if="courseDetail.sourceType === 2 && courseDetail.originalPrice">
              <div>原价：<span class="original-price">{{ courseDetail.originalPrice }}</span></div>
            </div>
            <div class="mgn-t10 flex font-s36 font-weight" v-if="courseDetail.sourceType === 2">
              <div>¥{{ courseDetail.marketingPrice }}</div>
            </div>
          </div>
        </div>
      </page-main>
      <el-card class="card-body">
        <el-tabs v-model="activeTab" class="mgn-b10">
          <el-tab-pane v-for="item in tabList" :key='item.key' :label='item.name' :name='item.key'></el-tab-pane>
        </el-tabs>
        <div class="table">
          <div v-if="activeTab === 'course'">
            <div class="scene-empty" v-if="!courseDetail.speakerUrl">
              <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
              <div>当前课程未添加课程详情图</div>
            </div>
            <template v-else>
              <div class="flex-start flex-wrap">
                <div v-for="item in courseDetail.speakerUrl" :key="item.normal" class="speaker-img">
                  <el-image :src="item.normal" class="speaker-img"></el-image>
                </div>
              </div>
            </template>
            <!-- <div v-else v-for="item in courseDetail.speakerUrl">
              <el-image :src="item.normal" class="speaker-img"></el-image>
            </div> -->
          </div>
          <div v-if="activeTab === 'exam'">
            <div class="scene-empty" v-if="!courseDetail.examVos">
              <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
              <div>当前课程未添加题目</div>
            </div>
            <div v-else>
              <div v-if="courseDetail.answerType === 1" class="font-s16 mgn-t10">{{ `${'共' + courseDetail.examVos.length + '题（答题时随机展示' + courseDetail.randomExamCount + '题）'}`}}</div>
              <div v-else class="font-s16 mgn-t10">{{ `${'共' + courseDetail.examVos.length + '题（答题时按顺序展示）'}`}}</div>
              <exam-item
                v-for="(item,index) in courseDetail.examVos"
                :item='item'
                :show-correct="!item.type"
                :index='index + 1'
                :key='item.examId'>
              </exam-item>
            </div>
          </div>
          <div v-if="activeTab === 'video'">
            <div class="scene-empty" v-if="!courseDetail.videoVos">
              <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
              <div>当前课程未添加视频</div>
            </div>
            <div v-else class="font-s16 mgn-b10">本课程共{{ courseDetail.videoCount || 0 }}个视频
              <el-table
              :data="courseDetail.videoVos || []"
              stripe
              style="width: 100%">
              <el-table-column
                align="center"
                label="视频"
                width="180">
                <template slot-scope="scope">
                  <div class="video-cover">
                    <img :src="scope.row.customCoverUrl || scope.row.coverUrl || courseDetail.coverUrl" alt=""
                         style="width:100%;height:100%;">
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="title"
                align="center"
                show-overflow-tooltip
                label="视频名称">
              </el-table-column>
              <el-table-column
                prop="duration"
                align="center"
                label="视频时长">
                <template slot-scope="scope">
                  {{
                    scope.row.duration >= 60 ? parseInt(scope.row.duration / 60) + '分' + scope.row.duration % (parseInt(scope.row.duration / 60) * 60) + '秒' : scope.row.duration + '秒'
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="category"
                align="center"
                label="分类">
                <template slot-scope="scope">
                  {{ scope.row.category ? scope.row.category.title : '/' }}
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                align="center"
                label="操作"
                width="150"
                v-if="courseDetail.sourceType !== 2">
                <template slot-scope="scope">
                  <div>
                    <el-button class="mgn-r10" type="text" @click="onOpenShowVideoModal(scope.row.videoId)">预览
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <fixed-action-bar  v-if="courseDetail.sourceType === 0 || courseDetail.sourceType !== 0 && courseDetail.isBuy">
      <template v-if="courseDetail.sourceType === 0">
        <el-button plain @click="onOpenOperationConfirmPop(id)" v-if="$auth('crm.courseMgmt.delete')" :disabled="courseDetail.isUsed === 1">删除课程</el-button>
        <el-button v-if="$auth('crm.courseMgmt.edit')" plain @click="onEditCourse(id)" :disabled="courseDetail.isUsed === 1">编辑课程</el-button>
        <el-button type="primary" v-if="$auth('crm.courseMgmt.share')" @click="onOpenShareCoursePop(id)">分享课程</el-button>
      </template>
      <template v-if="courseDetail.sourceType !== 0 && courseDetail.isBuy">
        <el-button type="primary" @click="onOpenShareCourse(id)">分享课程</el-button>
      </template>
    </fixed-action-bar>
    <SceneShareDepModal
      :visible="isShowShareCoursePop"
      sceneType='training'
      sceneTitle='课程'
      :sceneId="id"
      :isSubmit='isShareLoading'
      @onClose="onClosePop"
      @onSubmit='onSubmitShareDep'
    />
    <el-dialog title="分享课程" :visible.sync="isShowShareCourse" center>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="100">
        </el-table-column>
        <el-table-column
          prop="name"
          label="厂家"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="次数">
          <template slot-scope="scope">
            <el-input type="number" v-model="scope.row.degree"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowShareCourse = false">取 消</el-button>
        <el-button type="primary" @click="trainDegreeDistribute">确 定</el-button>
      </span>
    </el-dialog>
    <OperationConfirmPop :visible="isShowOperationConfirmPop"
                         :message="message"
                         :id="id"
                         title="课程删除操作确认"
                         cancelFont="取消"
                         confirmFont="确定删除"
                         @onSubmit="onDelCourse"
                         @onClose="onClosePop"/>
    <VideoPlayModal title="预览视频" :isDown='false' :visible='showPreviewMobileModal' :videoUrl='videoUrl'
                    @onClose='onCloseModal'/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PreviewCourseModal from '@/bizComponents/PreviewCourseModal'
import SendPreviewModal from '@/bizComponents/SendPreviewModal'
import SceneShareDepModal from '@/bizComponents/SceneShareDepModal'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import ExamItem from '@/bizComponents/ExamItem'

export default {
  name: 'LookCourseData',          // 跟文件名称对应
  components: {
    PreviewCourseModal,
    SendPreviewModal,
    OperationConfirmPop,
    VideoPlayModal,
    SceneShareDepModal,
    ExamItem
  },
  data() {
    return {
      // ------------------- 预览 -----------------------------
      videoUrl: '',
      showPreviewModal: false,//预览modal
      showPreviewMobileModal: false,//发送手机预览modal
      dialogTableVisible: false,//发送手机预览modal
      previewId: '',//预览活动的id
      isShowShareCoursePop: false,
      isShowShareCourse: false,
      isShowOperationConfirmPop: false,
      message: '请确认是否永久删除？',
      id: '',
      courseDetail: {},
      isClick: false,
      multipleSelection: [],
      tableData: [],
      distribute: false,
      isShareLoading: false,
      count: 0,
      showMask: false,
      tabList: [{
        name: '课程详情',
        key: 'course'
      }, {
        name: '视频',
        key: 'video'
      }, {
        name: '题目',
        key: 'exam'
      }],
      activeTab: 'course',
    }
  },
  mounted() {
    this.onInitTrainData()
    this.id = this.$route.params.courseId
    let currentTime = new Date().getTime()
    let lastTime = window.localStorage.getItem(`${this.id}clickContactTime`) || 0
    let timeHover = Math.floor(currentTime - lastTime) / (24 * 3600 * 1000)
    if (lastTime && timeHover >= 1) {
      this.isClick = false
    } else if (lastTime && timeHover < 1) {
      this.isClick = true
    } else {
      this.isClick = false
    }
  },
  methods: {
    ...mapActions(['getCourseDetail', 'onTrainRemoveCourse', 'contactUs', 'trainCourseDegreeDistribute', 'getChildFactoryList', 'getVideoPlayUrl', 'onTrainCourseDistribute']),
    onInitTrainData() {
      this.getCourseDetail({ courseId: this.$route.params.courseId }).then(res => {
        if (res.data) {
          this.courseDetail = res.data
          this.count = this.courseDetail.examVos.length
        }
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onOpenShowVideoModal(videoId) {
      this.getVideoPlayUrl({ videoId: videoId, courseId: this.id }).then(res => {
        let resData = res.data || []
        if (!resData.length > 0) {
          this.$message.error('暂无视频资源')
        }
        for (let item of resData) {
          if (item.indexOf('.mp4') > 0) {
            this.videoUrl = item
            this.showPreviewMobileModal = true
            return false
          }
          this.videoUrl = ''
        }
        this.$message.error('暂无视频资源')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    // ------------------------ 预览 -------------------
    onCloseModal() {
      this.showPreviewModal = false
      this.showPreviewMobileModal = false
      this.previewId = ''
      this.previewTitle = ''
    },
    onOpenOperationConfirmPop(id) {
      this.id = id
      this.isShowOperationConfirmPop = true
    },
    onOpenShareCoursePop(id) {
      this.id = id
      this.isShowShareCoursePop = true
    },
    onOpenShareCourse(id) {
      this.id = id
      this.getChildFactoryList().then(res => {
        this.tableData = res.data
      }, rea => {
        this.$message.error(rea.message)
      })
      this.isShowShareCourse = true
    },
    onDelCourse(id) {
      this.onTrainRemoveCourse(id).then(res => {
        this.$message({
          message: '删除成功！',
          type: 'success'
        })
        this.$router.back()
        this.onClosePop()
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onSubmitShareDep(arr) {
      this.isShareLoading = true
      this.onTrainCourseDistribute({ courseId: this.id, factoryIds: arr }).then(res => {
        this.isShareLoading = false
        this.$message({
          message: '分享成功！',
          type: 'success'
        })
        this.onClosePop()
      }, rea => {
        this.isShareLoading = false
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onClosePop() {
      this.isShowShareCoursePop = false
      this.isShowShareCourse = false
      this.isShowOperationConfirmPop = false
    },
    onEditCourse(id) {
      this.$router.push({ name: 'EditCourse', params: { courseId: id } })
    },
    contact() {
      this.contactUs({ courseId: this.id }).then(res => {
        this.$message.success('为了更好的对您进行服务，稍后我们的课程管理人员会主动联系您，请稍微等待，谢谢！')
        this.isClick = true
        window.localStorage.setItem(`${this.id}clickContactTime`,new Date().getTime())
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    trainDegreeDistribute() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择要分享的厂家')
        return
      }
      let courseDegreeInfo = this.multipleSelection.map(item => {
        return {
          factoryId: item.id,
          degree: item.degree
        }
      })
      let allDegree = this.multipleSelection.reduce((a, b) => {
        return a + Number(b.degree)
      }, 0)
      this.trainCourseDegreeDistribute({
        courseId: this.id,
        allDegree: allDegree,
        courseDegreeInfo: courseDegreeInfo
      }).then(res => {
        this.$message.success('分享成功')
        this.isShowShareCourse = false
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onMouseEnter() {
      if (this.showMask) return
      this.showMask = true
    },
    onMouseLeave() {
      if (!this.showMask) return
      this.showMask = false
    },
  },
}
</script>

<style lang="scss" scoped>
.content-box::-webkit-scrollbar {
  width: 0 !important;
}

.table-box {
  text-align: center;
  border-radius: 15px;
  margin-right: 10px;
  background: #DCEDFF;
  color: #3D61E3;
  padding: 5px;
  font-size: 12px;
}

.video-cover {
  width: 107px;
  height: 60px;
  background: #D8D8D8;
  margin: auto;
}

.box-shadow {
  box-shadow: 2px -2px 4px 0px #e2e3e7;
}

.title-nav-box {
  position: relative;
  z-index: 10;
}

.footer {
  background: #ffffff;
  height: 100px;
  padding-right: 100px;
  border-top: 1px solid #E9EAEB;
}

.circle {
  width: 16px;
  height: 16px;
  border: 2px solid #D7DCE1;
  border-radius: 50%;
  margin-right: 5px;
}

.question {
  line-height: 60px;
  width: 100%;
  color: #909399;
  text-align: center;
}

.contact {
  float: right;
}

.speaker-img {
  width: 100%;
}

.original-price {
  text-decoration: line-through red
}

.code-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 200ms;
  transform: translate3d(0, 100%, 0);
  z-index: 900;

  &.active {
    transform: translate3d(0, 0, 0);
  }
}

.course-img {
  width: 320px;
  height: 180px;
  background: #D8D8D8;
  cursor: pointer;
  overflow: hidden;
}

.video-play {
  font-size: 50px;
}

.card-body ::v-deep {
  padding: 12px 14px;
  flex-shrink: 0;
  margin: $g-main-wrap-margin;
  position: relative;

  .el-tabs {
    margin-top: -32px;
  }

  .el-tabs__header {
    margin-left: -34px;
    margin-right: -34px;

    .el-tabs__nav-wrap {
      padding-left: 34px;
    }
  }
}

.table {
  min-height: 400px;
}

.scene-empty {
  color: rgba(119, 132, 161, 1);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 10px;
  }
}
</style>
